Ø/* Overrides */

.section {
  margin-top: 1.5em;
}

.ui.header input {
  padding:0;
}


/* Override z-index */
.ui.selection.dropdown.active, .ui.selection.dropdown.visible {
  z-index: 99 !important;
}

.u-space-text {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}

.u-top-margin-title {
  margin-top: 1em;
}

.u-space-between {
  display: flex;
  justify-content: space-between;
}

.u-align-baseline {
  display: flex;
  align-items: baseline;
}

.u-section-title {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  display: block;
}

.u-fields-spacing {
  margin-right: 1em;
}

.u-display-block {
  display: block;
  width: 100%;
}
